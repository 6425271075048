//JOIN ilustrations and ilustrationsv2 folders to keep used ilustrations

import logoSquare from './images/allvit-logo.png';

import womanWithPhone from './images/woman-with-phone.jpg';

import bookshelfEmpty from './images/illustrations/bookshelf-empty.svg';

import boyCurriculum from './images/illustrations/boy-curriculum.svg';
import girlOnBooks from './images/illustrations/girl-on-books.svg';
import magnifier from './images/illustrations/magnifier.svg';

import extractedSmartStudent from './images/illustrations/extracted-smart-student.svg';
import topviewGuyTablet from './images/illustrations/topview-guy-tablet.svg';
import topviewStudentLaptop from './images/illustrations/topview-student-laptop.svg';
import topviewStudentsWorking from './images/illustrations/topview-students-working.svg';

import closeupAllinone from './images/illustrations/closeup-all-in-one.svg';
import closeupNotes from './images/illustrations/closeup-notes.svg';
import closeupSearch from './images/illustrations/closeup-search.svg';

import aPlussLogo from './images/illustrations/allvit-pluss-cover.png';
import topviewComputerAirpods from './images/illustrations/topview-computer-airpods.png';

import portraitIda from './images/pictures/ida.png';

import recruitmentCampaign from './images/recruitmentCampaign.jpg';

import allvitBannerBackground from './images/allvit-banner-bg.png';
import allvitGuruBanner from './images/guru-banner.png';
import bookGrid from './images/book-grid.png';

export const imageUrls = {
    aPlussLogo,
    allvitBannerBackground,
    bookGrid,
    bookshelfEmpty,
    boyCurriculum,
    allvitGuruBanner,
    closeupAllinone,
    closeupNotes,
    closeupSearch,
    extractedSmartStudent,
    girlOnBooks,
    logoSquare,
    magnifier,
    portraitIda,
    recruitmentCampaign,
    topviewComputerAirpods,
    topviewGuyTablet,
    topviewStudentLaptop,
    topviewStudentsWorking,
    womanWithPhone,
};
